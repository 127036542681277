import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/RES/render-01.jpg)" }}
      >
        <div className="container position-relative">
          <h1>UD-YA-NA</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “UD-YA-NA”{" "}
            </p>
            <p>
              <span>CLIENT </span> - Mr. RAMDOSS & Mrs. GAYATHRI RAMDOSS
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2020
            </p>
            <p>
              <span>LOCATION </span> - KADAYANALLUR, TENKASI DIST
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The site is part of the South zone of Kadayanallur in an area that
              is packed with old agraharam row houses. The site -measuring about
              1100 sq.feet, the site was accessible from both the north and
              south , lending itself to an interesting connect with its edges
              across streets.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The house was designed for a couple, a teenage daughter & son and
              their grandparents. The brief required us to provide a 3 bedroom
              dwelling with a central dining and living space with connects to
              the outdoors. The challenge was to bring natural ventilation as
              the side walls are shared by the adjacent houses mother wall
              ,considering the requirements.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              The site is part of a developing zone. A raised plinth became
              mandatory and in a way helped define the scale in relation to the
              edges. A building language that is expressive of our concerns to
              achieve Natural air and light in designed spaces was our main
              objective. The built form, comprises, confined masonry walls
              sitting on a raised plinth, oriented to keep the sun at bay and
              allowing for natural breezes to flow within the building. The
              house is aligned north – south on its longer axis owing to the
              dimensions and the orientation of the site. The east and west
              facades are shared as mother wall for the adjacent row houses. In
              addition, the service spaces- toilets, utilities, staircase etc.,
              located on the south and west help insulate the living areas
              further. The courtyard formed by manipulating the massing on the
              west creates a thermal barrier between the dining block. The inner
              spaces tie into this volume visually, providing an interesting
              play of light during the day. This gives the opportunity to use
              the spaces and enjoy mornings and evenings with comfortable
              ventilation. Large sets of doors coupled with wooden louvers help
              control the light and cut out glare. The prevailing breezes are
              channeled into the home through a vertical shaft over the puja
              space that works like a stack to flush the inside spaces with
              night-time cool air.
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              Interior surface temperature readings taken with an infrared
              thermometer post completion give a stable and consistent reduction
              in temperature by about 10 deg.c during the summer months.Air
              exchange is carried out by stack effect , making a comfortable
              room temperature.
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="assets/img/RES/render-01.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="assets/img/RES/SITE01.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="assets/img/RES/planterbox.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="assets/img/RES/KIDS-01.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="assets/img/RES/glasscanopy.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img
              src="assets/img/RES/Section.png"
              style={{ objectFit: "contain" }}
              alt="Card 6"
            />
          </div>
          <div className="card7">
            <img src="assets/img/RES/SITE02.jpg" alt="Card 7" />
          </div>
          <div className="card11">
            <img src="assets/img/RES/render-02.png" alt="Card 11" />
          </div>
          <div className="card12">
            <img
              src="assets/img/RES/Groundfloor1.png"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div>
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential;
