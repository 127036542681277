import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential1 = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/residential1/image8.jpg)" }}
      >
        <div className="container position-relative">
          <h1>KUDIL</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “KUDIL”{" "}
            </p>
            <p>
              <span>CLIENT </span> - Mr. Veeresparan & Mrs.Rathika rani
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2021
            </p>
            <p>
              <span>LOCATION </span> - Erumalainaickenpatti, THENI DIST
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The site is situated in the West zone of Erumalainaickenpatti,
              southwest of Devadanapatti. It is surrounded by temples and
              historic, yet dilapidated, houses. The plot spans approximately
              485 square feet, with access available from both the north and
              west directions.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The holiday house is designed to accommodate a couple and their
              two sons' families. The brief required a 2-bedroom duplex dwelling
              with a central living space that connects the levels, along with a
              kitchen, one common bathroom, and one attached bathroom. A
              significant challenge was to incorporate natural ventilation, as
              the site is characterized by a hot climate and lacks adjacent
              shading elements to mitigate thermal effects.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              The site is located in a densely built area. Our primary objective
              was to achieve natural air and light in the designed spaces
              through a sustainable approach, utilizing volume manipulation and
              sun orientation to create a cooling effect.
            </p>
            <p>
              The built form comprises confined masonry walls on a raised
              plinth, oriented to minimize sun exposure while allowing natural
              breezes to flow through the building. The house is aligned along a
              north-south axis to maximize these effects, in response to the
              site’s dimensions and orientation. The east facade faces an
              existing temple, while the western facade, exposed to direct
              sunlight due to the open area, is designed with a green patch on
              the northwest side of the site to reduce heat impact.
              Additionally, window and opening placement on the western wall was
              restricted to lower the U-value.
            </p>
            <p>
              To introduce natural light, we incorporated an open-to-sky (OTS)
              feature above the staircase on the western side, which facilitates
              a stack effect, pulling hot air upward and out of the building.
              This design also ensures a well-lit space during the daytime,
              reducing the need for artificial lighting. In the bedrooms, two
              windows of equal size were strategically placed diagonally to
              promote cross ventilation, while the window widths were limited to
              2 feet to control heat gain.
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              Treated bamboo with filled reinforcement is used in the OTS,
              providing both an aesthetic appeal and security. Mahogany timber
              is used for the windows, while engineered wood is selected for the
              bedroom doors, and teak wood for the main door. Custom-designed
              Athangudi tiles were employed for the flooring, offering a blend
              of traditional and contemporary aesthetics while providing a
              cooling effect during the summer months.
            </p>
            <p>
              The corners of the house’s walls are chamfered to prevent chipping
              and to add an ornamental touch, inspired by the nearby Chettinad
              houses. The exterior is painted white to reduce thermal gain.
              Post-construction infrared thermometer readings show a consistent
              reduction in interior surface temperatures by approximately 8°C
              during the summer months. The stack effect aids in air exchange,
              contributing to a comfortable indoor environment.
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="/assets/img/residential1/image1.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="/assets/img/residential1/int1-2.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="/assets/img/residential1/image3.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="/assets/img/residential1/image4.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="/assets/img/residential1/image5.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img
              src="/assets/img/residential1/image6.jpg"
              style={{ objectFit: "contain" }}
              alt="Card 6"
            />
          </div>
          <div className="card7">
            <img src="/assets/img/residential1/image7.jpg" alt="Card 7" />
          </div>
          <div className="card11">
            <img src="/assets/img/residential1/image8.jpg" alt="Card 11" />
          </div>
          <div className="card12">
            <img
              src="/assets/img/residential1/image9.jpg"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div>
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential1;
