import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential2 = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{
          backgroundImage: "url(assets/img/residential2/res2-HEADER.jpg)",
        }}
      >
        <div className="container position-relative">
          <h1>TEMPLE VIEW VILLA</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “TEMPLE VIEW VILLA”{" "}
            </p>
            <p>
              <span>CLIENT </span> - Mr. .KARTHIK & FAMILY
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2022
            </p>
            <p>
              <span>LOCATION </span> - PARANUR, VILLUPARAM DIST
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The site is nestled between Villupuram and Tirukoilur in a serene
              rural area, surrounded by lush farmlands and hills, located on an
              Agraharam street. Spanning approximately 1500 square feet, the
              site is accessible from the north.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The house was designed for a retired couple. The brief called for
              a space that combines traditional public gathering areas with
              residential living. The design needed to include three bedrooms, a
              kitchen, a living room, and a gallery space for special occasions.
              One of the main challenges was ensuring natural ventilation, given
              that the side walls are adjacent to a public hall and a temple
              garden.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              <h5>Climate and Design</h5>
              Situated in a rural zone with a hot and humid climate, the house
              is designed with a focus on comfort and environmental
              responsiveness. The building features confined masonry walls set
              on a raised plinth, oriented to minimize sun exposure and maximize
              natural ventilation. The house is aligned along the north-south
              axis due to the site’s dimensions and orientation, with the east
              and west facades abutting the adjacent public hall and temple
              garden.
              <h5>Spatial Design and Light</h5>
              The living room integrates a double-height open-to-sky (OTS) area
              and a staircase to introduce natural light and create a spacious
              feel that extends to the mezzanine floor. This design enhances the
              play of light throughout the day and offers an engaging view from
              the mezzanine during festivals and special occasions.
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              To manage interior temperatures and provide comfort, traditional
              Athangudi tiles are used for flooring, which also aids senior
              citizens with knee pain. Teak wood is chosen for doors and windows
              to add a natural and appealing aesthetic. The design incorporates
              longer windows in gathering spaces to enhance ventilation and
              provide an attractive view. Polarized glass is used for
              open-to-sky areas to manage heat and light effectively.
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="assets/img/residential2/res2-1.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="assets/img/residential2/res2-2.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="assets/img/residential2/res2-3.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="assets/img/residential2/res2-7.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="assets/img/residential2/res2-5.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img src="assets/img/residential2/res2-8.jpg" alt="Card 6" />
          </div>
          <div className="card7">
            <img
              src="assets/img/residential2/res2-6.jpg"
              alt="Card 7"
              style={{ objectFit: "fill" }}
            />
          </div>
          <div className="card11">
            <img src="assets/img/residential2/res2-4.jpg" alt="Card 11" />
          </div>

          {/* <div className="card12">
            <img
              src="assets/img/residential2/res2-4.jpg"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div> */}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential2;
